<template>
  <div class="homesss">
    <div class="main">
      <div class="head">
        <!-- <img class="bg" src="@/static/head.png"> -->
        <div class="name">在线监测系统</div>
        <!-- <img @click="toLogin" class="logout" src="@/static/logout.png"> -->
        <div class="right-btn">
          <span class="left"  @click="showUpdate">修改密码</span>
          <span class="right" @click="toLogin">退出</span>
        </div>
      </div>
      <div style="min-width: 100%;height: 100%; display: flex; flex-wrap: wrap;">
      <div class="aside">
      <el-menu :unique-opened="true" default-active="1" style="width: 100%;" :router="true" background-color="#16364a"
        text-color="#fff" active-text-color="#2BC9DE">
        <el-submenu index="1" >
          <template slot="title">
            <img src="@/static/zxjc.png" style="width: 2.4rem;height: 2.4rem;margin-right: 0.8rem;">
            <span style="font-size: 1.2rem">所有设备</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="HjsjIndex1">
              <span style="margin-left: 0.8rem;font-size: 1.2rem">环境设备</span>
            </el-menu-item>
          </el-menu-item-group>
        </el-submenu>
        <!-- 设备管理 -->
        <el-submenu index="2" >
          <template slot="title">
            <img src="@/static/sbgl.png" style="width: 2rem;height: 2rem;margin-right: 0.8rem;">
            <span style="font-size: 1.2rem">设备管理</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="EquipmentList">
              <span style="margin-left: 0.8rem;font-size: 1.2rem">设备信息管理</span>
            </el-menu-item>
          </el-menu-item-group>
        </el-submenu>
        <el-submenu index="3" >
          <template slot="title">
            <img src="@/static/jg.png" style="width: 2rem;height: 2rem;margin-right: 0.8rem;">
            <span style="font-size: 1.2rem">预警管理</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="gjxx">
              <span style="margin-left: 0.8rem;font-size: 1.2rem">预警信息</span>
            </el-menu-item>
            <el-menu-item index="warningSet">
              <span style="margin-left: 0.8rem;font-size: 1.2rem">预警设置</span>
            </el-menu-item>
          </el-menu-item-group>
        </el-submenu>
        <el-submenu index="4" >
          <template slot="title">
            <img src="@/static/zh.png" style="width: 2rem;height: 2rem;margin-right: 0.8rem;">
            <span style="font-size: 1.2rem">账号管理</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="glkh">
              <span style="margin-left: 0.8rem;font-size: 1.2rem">客户管理员</span>
            </el-menu-item>
          </el-menu-item-group>
        </el-submenu>
        <el-submenu index="5" >
          <template slot="title">
            <img src="@/static/zh.png" style="width: 2rem;height: 2rem;margin-right: 0.8rem;">
            <span style="font-size: 1.2rem">系统数据</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="lssj">
              <span style="margin-left: 0.8rem;font-size: 1.2rem">历史数据</span>
            </el-menu-item>
          </el-menu-item-group>
          <el-menu-item-group>
            <el-menu-item index="czrz">
              <span style="margin-left: 0.8rem;font-size: 1.2rem">操作日志</span>
            </el-menu-item>
          </el-menu-item-group>
        </el-submenu>

      </el-menu>
    </div>
      <div class="containerxxx">
        <router-view></router-view>
      </div>

    </div>
    <!-- 遮罩弹窗 -->
    <div
      style="width: 100%; height: 100%; background-color: #a3a3a3; opacity: .5; z-index: 99; position: absolute; top: 0; left: 0;right: 0; bottom: 0;"
      v-if="dataGj">

    </div>
    <div class="gjxx" v-if="dataGj">
      <div class="hb">
        告警信息
      </div>
      <div class="qx" @click="dataGj = false">
        <img src="../static/Union.png">
      </div>
      <div class="main1">
        <div class="box" v-for="item in data" @click="xq(item)">
          <div class="m1">
            <div class="l" style="color: rgb(3, 91, 150);">
              设备编号：{{item.serialNumber}}
            </div>
            <div class="r" v-if="item.alarmDuration">
              告警时长:{{item.alarmDuration}}分钟
            </div>
            <div class="r" v-if="!item.alarmDuration">
              告警暂未结束
            </div>
          </div>
          <div class="m1">
            <div class="l" style="color: rgb(3, 91, 150);">
              设备名称：{{item.name}}
            </div>
            <div class="r"></div>
          </div>

          <div class="m1">
            <div class="l" style="color: rgb(3, 91, 150);">
              设备位置：{{item.address}}
            </div>
            <div class="r"></div>
          </div>
          <div class="m1">
            <div class="l" style="color: rgba(249, 69, 69, 1);">
              异常内容：{{item.abnormalCause}}
            </div>
            <div class="r" style="width: 20rem; font-size: 1.2rem;">
              {{item.startTime}}
            </div>
          </div>
        </div>
      </div>
      <div class="but">
        <div class="left" @click="dataGj = false">
          确认
        </div>
      </div>
    </div>
  </div>
  <el-dialog title="操作" :visible.sync="dialogFormVisible">

<el-form :model="form" style="margin-top: 1rem;" label-width="80px">
  <el-form-item  label="原密码:" >
    <el-input v-model="form.oldPassword" show-password></el-input>
  </el-form-item>
  <el-form-item  label="新密码:" >
    <el-input v-model="form.password" show-password></el-input>
  </el-form-item>
</el-form>
<div slot="footer" class="dialog-footer">
  <el-button @click="cancelEdit">取 消</el-button>
  <el-button  type="primary" @click="saveEdit">保存</el-button>
</div>
</el-dialog>
</div>
</template>

<script>
  import axios from "axios"
  export default {
    name: 'Home',
    data() {
      const item = {
        date: '2016-05-02',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄'
      };
      return {
        dialogFormVisible: false,
        tableData: Array(20).fill(item),
        Qx: {
          zx: false,
          xm: false,
          sb: false,
          sg: false,
          gj: false,
          zh: false,
          rk: false,
          sj: false
        },
        form: {
          oldPassword: '',
          password: ''
        },
        list: [],
        dataGj: false,
        data: []
      }
    },
    components: {

    },
    created() {

      console.log('开始');

      // console.log(sessionStorage.getItem("Lg"));
    },
    mounted() {
      this.ToData_gj()
    },
    methods: {
      xq(item){
        this.dataGj = false
				this.$newGet('/warning/record/readRecord/'+item.id).then(res=>{

				})
        this.$router.push('/gjxx1?id='+ item.equipmentId + '&type=' + item.type)
      },
      showUpdate(){
        console.log(111)
        this.dialogFormVisible = true
      },
      cancelEdit() {
        this.dialogFormVisible = false
      },
      saveEdit(){
        this.$newPost('/customer/updatePassword',this.form).then(res => {
          if (res.code == 2000) {
            this.$message({
              message: res.message,
              type: 'success'
            });
            this.dialogFormVisible = false
            this.form = {
              oldPassword: '',
              password: ''
            }
          } else {
            this.$message({
              message: res.message,
              type: 'warning'
            });
          }
        })

      },
    ToData_gj() {
        let obj = {
          "page": 1,
          "read": 0,
          "size": 100
        }
        this.$newPost('/warning/record/page', obj).then(res => {
          console.log(res, 'new!!!!');
          if (res.code == 2000) {
            if (res.data.records.length != 0) {
              this.dataGj = true
              this.data = res.data.records
            }
          } else {
            this.$message({
              message: res.message,
              type: 'warning'
            });
          }
        })
      },
      toLogin() {
				this.$newGet('/index/logout').then(res=>{
					// this.$ajax('logout', 'post')
					sessionStorage.clear()
					// this.$router.push('/login')
					this.$router.push('/login')
				})

      },
      sjbf() {
        this.$ajax('sjkbf', 'post').then(res => {
          console.log(res);
          window.open(res.data.sqlUrl)
        })
      },
    }
  }
</script>

<style scoped lang="scss">
  .gjxx {
    width: 60rem;
    height: 35rem;
    background-color: rgba(254, 254, 254, 0.9);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
    display: flex;
      justify-content: center;
      flex-wrap: wrap;

    ::-webkit-scrollbar {
      display: none;
    }

    .hb {
      
      width: 52.2rem;
      height: 3rem;
      line-height: 3rem;
      margin-top: 1rem; 
      color: #202020;
      text-align: center;
      font-size: 2.0rem;
      border-bottom: 1px solid #202020;
    }

    .qx {
      position: absolute;
      top: 1.2rem;
      right: 3.2rem;
      width: 1.2rem;
      height: 1.2rem;
      line-height: 1.2rem;
      color: #202020;
      font-size: 1.2rem;

      img {
        width: 1.2rem;
        height: 1.2rem;
      }
    }

    .main1 {
      width: 100%;
      height: 25rem;
      padding: 0.8rem 2.8rem;
      overflow: auto;
      box-sizing: border-box;
      font-size: 1.0.8rem;

      .box {
        width: 50rem;
        min-height: 8rem;
        box-sizing: border-box;
        border-bottom: 1px solid rgba(24, 42, 55, 0.8);
        margin-bottom: 0.8rem;
        cursor:pointer;

        .m1 {
          width: 50rem;
          min-height: 4.4rem;
          display: flex;
          justify-content: space-between;
          margin-bottom: 0.3rem;

          .l {
            width: 45rem;
            min-height: 4.4rem;
            display: flex;
            align-items: center;
          }

          .r {
            width: 15rem;
            min-height: 4.4rem;
            display: flex;
            align-items: center;
            text-align: right;
            color: #202020;
            
          }
        }
      }
    }

    .but {
      width: 52.2rem;  
      height: 4rem;
      display: flex;
      margin-top: 2rem;
      color: #FFFFFF;
      border-top: 1px solid  #202020;
      display: flex;
      justify-content: center;
      align-items: center;

      .left {
        width: 7rem;
        height: 3rem;
        background: linear-gradient(114.36deg, #2ba2de 15.58%, #0f6ee2 85.68%);
        border-radius: .4rem;
        text-align: center;
        line-height: 3rem;
      }

      .right {
        width: 7rem;
        height: 3rem;
        border: 1px solid rgba(43, 201, 222, 1);
        border-radius: .4rem;
        text-align: center;
        line-height: 3rem;
      }
    }
  }

  .homesss {
    width: 100%;
    background-color: #ffffff;
    display: flex;
  }

  .head {
    // position: relative;
    // z-index: 2;
    // left: 31.2rem;
    // right: 0;
    // top: 0;
    width: 100%;
    height: 4rem;
    background-color: #24b2ea;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .name {margin-left: 2rem;
      font-weight: 400;
      font-size: 1.6rem;
      color: #ffffff;
      text-shadow: 0px 0px 4px rgba(255, 255, 255, 0.45);
      
      
    }

    .right-btn {
      width: 12.2rem;
      height: 4rem;
      border-radius: 0.2rem;
      display: flex;
      align-items: center;
      font-size: 1.0rem;
      
      .left{
        cursor: pointer;
        margin-right: 1rem;
        color: #FFFFFF;
      }
      .right{
        cursor: pointer;
        color: #FFFFFF; 
      }
    }
  }

  .aside {

    width: 16%;
    height: 100%;
    background-color: #16364a;
    padding-top: 1rem;

    // /deep/ .el-menu {
    //   border: none;
    //   margin-top: 1rem;
    // }

    .logo {
      width: 100%;
      height: 20rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .main {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-content: flex-start;
    width: 100%;
    min-height: 100vh;
    background-color: #ffffff;

    // padding: 2.2rem 2rem;
    .containerxxx {
      // position: relative;
      background-color: #ffffff;
	  border-top: 0.0625rem solid rgb(197, 197, 197);
      // margin: 2.2rem 2rem;
    width: 84%;
    height:100%;
    }
  }

  ::v-deep  .el-menu-item-group__title{
    padding: 0rem;
  }

</style>
